.App {
  height: 100%;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-devices: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.xyz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginCard {
  width: 350px;

}

.loginForm {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #d8d8da;
}

.loginButton {
  margin-top: 20px;
  width: 100%;
}

@font-face {
  font-family: 'MuseoSans100';
  src: local("MuseoSans100"), url("./fonts/MuseoSans-100.otf") format("opentype");
}
@font-face {
  font-family: 'MuseoSans300';
  src: local("MuseoSans300"), url("./fonts/MuseoSans-300.otf") format("opentype");
}
@font-face {
  font-family: 'MuseoSans500';
  src: local("MuseoSans500"), url("./fonts/MuseoSans-500.otf") format("opentype");
}
@font-face {
  font-family: 'MuseoSans700';
  src: local("MuseoSans700"), url("./fonts/MuseoSans-700.otf") format("opentype");
}